module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-151942979-1"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"schemas":{"project":{"Main":{"uid":{"type":"UID","config":{"label":"pathname","placeholder":"A descriptive URL path name eg www.zagorestoration.co.uk/a-descriptive-pathname"}},"title":{"type":"StructuredText","config":{"single":"heading1, heading2, heading3, heading4, heading5, heading6","label":"title"}},"project_description":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, o-list-item","label":"project description"}},"image1":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"image1"}},"image2":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"image1"}},"image3":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"image1"}}}},"nav":{"Main":{"logo":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"logo"}}}},"assets":{"Main":{"logo":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"logo"}}}},"hero":{"Main":{"hero_text":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, o-list-item","label":"hero text"}},"image1":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"image1"}},"image2":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"image2"}},"image3":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"image3"}},"image4":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"image4"}}}}},"repositoryName":"zago-restoration","accessToken":"MC5YYTNobFJBQUFDTUE1anpn.77-977-977-977-9Ek0K77-9EyQOSQzvv70k77-977-977-977-977-977-977-9NikJbO-_ve-_vWzvv73vv73vv70"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"c4185758453a23cfc1014840758d2ee6"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
